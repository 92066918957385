import FileViewer from '@/components/FileViewer.vue';
import { File } from '@/interfaces/file.interface';
import Vue from 'vue';

export default Vue.extend({
  components: { FileViewer },

  data() {
    return { showFileDialog: false, item: null as null | File };
  },

  methods: {
    openFile(item: File) {
      this.item = item;
      this.showFileDialog = true;
    },
  },
});
