





















































import Vue, { PropType } from 'vue';
import { File } from '@/interfaces/file.interface';
import { mapGetters } from 'vuex';
import saveFileToDisk from '@/utils/save-file-to-disk';
import FileViewerImage from '@/components/FileViewerImage.vue';
import FileViewerVideo from '@/components/FileViewerVideo.vue';
import FileViewerPdf from '@/components/FileViewerPdf.vue';

export default Vue.extend({
  name: 'FileViewer',

  components: {
    FileViewerImage,
    FileViewerVideo,
    FileViewerPdf,
  },

  data() {
    return { fullscreen: false };
  },

  props: {
    item: Object as PropType<File>,
    value: Boolean,
  },

  computed: {
    ...mapGetters('auth', ['token']),

    show: {
      set(value: boolean) {
        this.$emit('input', value);
      },
      get(): boolean {
        return this.value;
      },
    },

    fileUrl(): string {
      return `${process.env.VUE_APP_BASE_URL}${this.item.location}?token=${this.token}`;
    },

    mimeType(): string {
      if (!this.item) {
        return '';
      }

      return this.item.mimeType;
    },

    isImage(): boolean {
      return /^image\/.*$/.test(this.mimeType);
    },

    isVideo(): boolean {
      return /^video\/.*$/.test(this.mimeType);
    },

    isPdf(): boolean {
      return this.mimeType === 'application/pdf';
    },
  },

  watch: {
    show(newValue) {
      if (newValue) {
        fetch(this.fileUrl);
      }
    },
  },

  methods: {
    downloadFile() {
      saveFileToDisk(this.item, this.token);
    },

    close() {
      this.show = false;

      if (this.fullscreen) {
        this.toggleFullscreen();
      }
    },

    exitFullscreen() {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
      this.fullscreen = false;

      const viewer = this.$refs.viewer as HTMLElement;
      viewer.removeEventListener('fullscreenchange', this.exitFullscreen);
    },

    toggleFullscreen() {
      const viewer = this.$refs.viewer as HTMLElement;
      if (!viewer.requestFullscreen) {
        return;
      }

      if (!this.fullscreen) {
        viewer.requestFullscreen();
        this.fullscreen = true;

        setTimeout(() => {
          viewer.addEventListener('fullscreenchange', this.exitFullscreen);
        }, 1000);
      } else {
        this.exitFullscreen();
      }
    },
  },
});
