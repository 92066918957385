import { File } from '@/interfaces/file.interface';
import { v4 as uuidv4 } from 'uuid';

export default (item: File, localeId: string, userId: string): string => {
  const timestamp = Math.floor(new Date(item.created).getTime() / 1000);

  // currently not checked in api so use random string
  const hmac = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(0, 5);

  const shareUuid = uuidv4();

  return `${process.env.VUE_APP_API_URL}/share/${localeId}/${item.id}/${timestamp}/${userId}/${hmac}/${shareUuid}`;
};
