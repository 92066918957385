import { File } from '@/interfaces/file.interface';

export default async (item: File, token: string): Promise<void> => {
  const ext = item?.location?.split('.')?.pop();
  const downloadName = `${item.title}.${ext}`;

  const url = `${process.env.VUE_APP_BASE_URL}/${item.location}?token=${token}`;

  const response = await fetch(url);
  const data = await response.blob();

  const downloadUrl = URL.createObjectURL(data);
  const a = document.createElement('a');

  a.href = downloadUrl;
  a.download = downloadName;
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(downloadUrl);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
};
